.news-side-view {
  padding: 20px 0;
  border-top: 1px solid #dfe5e9 ;

  &__img {
    width: 100%;
    height: auto;
  }

  &__data {
    padding: 19px 0;
    color: #9f9f9f;

  }

  &__title,&__data {
    font: 13px/21px sf_ui_displaymedium;
  }
}