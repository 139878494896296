.news {
 margin-bottom: 50px;

  &__title{
    padding-bottom: 20px;
    p {
      font: 40px/50px sf_ui_displayregular;
    }

    a {
      font: 15px/21px sf_ui_displaymedium;
      white-space: nowrap;
      align-self: baseline;
      padding-top: 20px;
    }
  }

  &__new {

    padding: 30px 20px;
    background-color: #ffffff;

    &__img {
      width: 100%;
    }

    &__data {
      padding: 19px 0;
      color: #9f9f9f;
    }

    &__title {

    }

  }

}

@media screen and (max-width: 767px) {
  .news {
    &__title {
      justify-content: space-around;
      text-align: center;
      width: 60%;
      margin: auto;
    p{
      font-size: 24px;
      line-height: 32px;
    }
    }

    &-mobile {

      .news__new {
        padding: 0;

        img {
          display: block;
          width: 64%;
          margin: auto;
          padding: 20px 0;
        }
        a {
          width: 100%;
          text-align: center;
          padding-bottom: 20px;
          display: block;
        }
      }
    }
  }
}
