.two-col {
  text-align: left;
  width: 100%;

  thead {
    background-color: #f0f5f8;
  }



  tr:nth-child(even) {
    background-color: #f0f5f8;

  }

  td:first-child{
    width: 500px;
  }

  td:last-child {
    text-align: center;
  }
}