
html,body {
  //max-width: 1600px;
  min-width: 375px;
  margin: auto;
  background-color: #f5f8fa;
  -webkit-font-smoothing: antialiased;
  color: black;
  width: 100%;
}

header{
  width: 100%;
}

a,a:hover {
  color: #6e8494;
  text-decoration: underline;
}


.content-wrap {
  width: 1200px;
  margin: auto;
}

.mobvis {
  display: none;
}

@media screen and (max-width: 1200px)  {
  .content-wrap {
    width: 90%;
    margin: 0 5%;
  }
  .tablet-hidden{
    display: none;
  }

  .tablet-100 {
    width: 100%;
  }
}


@media screen and (max-width: 767px) {
  .mhidden {
    display: none;
  }
  .content-wrap {
    width: 100%;
    margin: 0;
  }

  .mobvis {
    display: inherit;
  }
}


.no-border {
  border: none !important;
}

.breadcrumbs {


  a,a:hover {

    span {
      color: inherit;
    }
    color: #6e8494;
  }

  span {
    color : #9f9f9f;
  }
}

.sb-wrap {
  display: flex;
  width: 85%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}


@import "blocks/top-block/top-block";
@import "blocks/contact-block/contact-block";
@import "blocks/top-nav/top-nav";
@import "blocks/menu/menu";
@import "blocks/main-slider/main-slider";
@import "blocks/sidebar/sidebar";
@import "blocks/button/button";
@import "blocks/icon-links/icon-links";
@import "blocks/news-side-view/news-side-view";
@import "blocks/doctors-slider/doctors-slider";
@import "blocks/news/news";
@import "blocks/content/content";
@import "blocks/footer/footer";
@import "blocks/copyright/copyright";
@import "blocks/bredcrumbs/breadcrumbs";
@import "blocks/specialists-list/specialists-list";
@import "blocks/doctor/doctor";
@import "blocks/near-posts/near-posts";
@import "blocks/comments/comments";
@import "blocks/two-col/two-col";
@import "blocks/feedback-form/feedback-form";
@import "blocks/mobile-menu/mobile-menu";

