.copyright{
  background: #ffffff;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }



  &__logo {

    padding: 40px 0;


  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font: 16px/21px sf_ui_displayregular;


    a{
      color: #000;
    }
  }



  &__wrap {
    display: flex;
  }
}


@media screen and (max-width: 1000px) {
  .copyright {
    & > div {
      justify-content: space-around;
    }
  }
}