.menu, .sub-menu {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-content: center;
  list-style: none;
  padding: 35px 0;
  text-transform: uppercase;
  margin: 0 auto;
  background-color: #f5f8fa;
  font-family: sf_ui_displayregular;
  font-size: 15px;
  line-height: 21px;

  li {
    background-color: #f5f8fa;

    a {
      color: #6e8494;
      text-decoration: underline;
    }
  }

  .menu-item-has-children {
    cursor: auto;
    transition: margin-bottom 0.5s ease 0s;

    .sub-menu {
      position: absolute;
      left: 0;
      width: 100%;
      top: (91-56) * 1px;
      overflow: hidden;
      z-index: -2;
      transition: all 0.5s ease 0s;
    }
  }

  .menu-item-has-children:hover {
    margin-bottom: 56px;
  }

  .menu-item-has-children:hover .sub-menu {
    top: (91-35) * 1px;
    z-index: 0;
  }

  &-sidemenu {
    font: 14px/16px sf_ui_displaymedium;
    text-transform: none;
    flex-direction: column;
    padding: 0;
    padding-bottom: 40px;
    border-bottom: 1px solid #dfe5e9;
    margin-bottom: 20px;

    li {
      padding: 13px 0;

      span {
        color: #9f9f9f;
      }
    }

    .current-menu-item {

      a {
        color: #9f9f9f;
        text-decoration: none;
      }
    }

    li:last-child {
      padding-bottom: 0;
    }
  }

}

