.doctor {


  &__main {
    display: flex;
    flex-direction: row;
    align-items: flex-start ;

    img{
      max-width: 90px;
      max-height: 90px;
    }

    h1 {
      font: 40px/40px sf_ui_displayregular;
      padding: 0;
      margin: 0;
      white-space: nowrap;
    }
  }

  &__title{
    padding-left: 50px;

  }


  &__position {
    font: 16px/27px sf_ui_displaylight;
    padding: 30px 0;
    max-width: 90%;
  }


  &__exp {
    margin-bottom: 45px;
  }

  &__operations {
    background-color: #f0f5f8;
    font: 21px/21px sf_ui_displaymedium;
    padding: 18px 60px;
    margin-left: -10%;
  }

  &__about {

  }
}

@media screen and ( max-width: 767px) {

  .doctor {



    &__main {
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 27px;
        white-space: normal;
      }
    }

    &__title{
      padding-left: 0;
    }

  }

}