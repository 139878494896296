.main-slider {
  margin-bottom: 50px;

  .swiper-container {
    width: 100%;
  }

  .swiper-button-prev, .swiper-button-next {
    background: url("../img/main-slider/arrows.png");
    background-position: 0 0;
    width: 50px;
    height: 50px;
    left: 10%;
    top: 80%;
  }

  .swiper-button-next {
    background-position: 50px 0;
    margin-left: 80px;
  }

  .swiper-slide {
    position: relative;

    p {
      position: absolute;
      font-family: sf_ui_displaylight;
      font-size: 54px;
      line-height: 72px;
      color: #ffffff;
      left: 10%;
      top: 30%;


      span {
        text-align: right;
        font-size: 21px;
        line-height: 24px;
      }
    }

  }
}

@media screen and (max-width: 1200px) {
  .main-slider {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    .swiper-slide p {
      top: 20%;
    }
  }
}

@media screen and (max-width: 900px) {
  .main-slider {

    .swiper-container {

    }

    .swiper-slide p {
      top: 10%;

    }
  }
}

@media screen and (max-width: 768px) {
  .main-slider {
    margin-bottom: 50px;
    overflow: hidden;
    position: relative;

    .swiper-container {
      width: 200%;
      margin-left: -50%;
      position: static;

    }

    .swiper-slide {
      //position: static;

      p {
        text-align: center;
        width: 25%;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        font-size: 27px;
        line-height: 40px;
      }
    }

    .swiper-button-prev, .swiper-button-next {

      left: 10%;
      top: 50%;
    }

    .swiper-button-next {

      margin-left: 80px;
      left: inherit;
      right: 10%;
    }


  }
}

