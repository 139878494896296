.feedback-form {

  .title,.comment-reply-title {
    text-align: center;
    font: 40px/72px sf_ui_displayregular;
  }
  input,textarea {
    display: block;
    width: 80%;
    height: 50px;
    font:  13px/21px sf_ui_displaylight;
    margin: 10px auto;
    border-radius: 3px;
    outline:none;
    border: 1px solid #dfe5e9;
    padding-left: 15px;

    &::-webkit-input-placeholder {}
    &::-moz-placeholder          {}/* Firefox 19+ */
    &:-moz-placeholder           {}/* Firefox 18- */
    &:-ms-input-placeholder      {}
  }

  label[for="attachment"]{
    display: block;
    width: 80%;
    font:  13px/21px sf_ui_displaylight;
    margin: auto;
  }

  input[type=file]{
    height: auto;
    width: auto;
    border: none;
    margin: 10px 40px;
  }

  textarea {
    padding-top: 10px;
    height: 110px;
  }

  .submit {
    width: 50%;
    margin: auto;
  }

  .button {
    margin: 30px auto;
    width: 50%;
    font: 16px/21px sf_ui_displayregular ;
    height: 50px !important;
  }

}