.specialists-list {



  &__title {
    background-color: #dfe5e9;
    padding: 40px 40px;
    font: 21px/30px sf_ui_displaymedium;
    margin-bottom: 50px;
  }

  &__title:not(:first-of-type){
    margin-top: 80px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
    padding: 20px;
    height: 300px;
    justify-content: center;


    img {
      width: 90px;
      height: 90px;
    }

    a {
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 20px;
      font: 16px/24px sf_ui_displayregular;
    }

    p {
      font: 14px/21px sf_ui_displaylight;
      padding: 0 15px;
      max-height: 105px;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: 767px){
  .specialists-list {
    &__item {
      margin: 5% 5%;
    }
  }
}