.doctors-slider {
  width: 107.14%;
  margin-left: -7.14%;
  padding-bottom: 80px;

  &__title {
    padding-bottom: 20px;

    p {
      font: 40px/50px sf_ui_displayregular;
    }

    a,a:hover {
      font: 15px/21px sf_ui_displaymedium;
      white-space: nowrap;
      align-self: baseline;
      padding-top: 20px;
      color: rgb(110, 132, 148);
    }
  }

  &__slide {
    background-color: #ffffff;
    min-height: 150px;
  }

  &__slide > div{
    font: 16px/21px sf_ui_displayregular;

    img {
      padding: 30px;
      width: 150px;
      height: 150px;
    }

    a {
      padding-bottom: 14px;
    }

    p {
      max-width: 500px;
      font-family: sf_ui_displaylight;
    }
  }

  //.swiper-container {
  //  width: 107.14%;
  //  margin-left: -7.14%;
  //}

  .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D%27%23000%27%2F%3E%3C%2Fsvg%3E");
    right: 30px;
  }

  .swiper-button-prev {
  background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D%27%23000%27%2F%3E%3C%2Fsvg%3E");
    left: 30px;
  }

}

@media screen and (max-width: 1200px) {
  .doctors-slider {
    width: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 767px) {


  .doctors-slider, .swiper-container {
    padding: 0;
    width: 100%;
    margin: 0;
  }

  .doctors-slider {




    &__title {
      text-align: center;
      p {
        width: 100%;
        font-size: 24px;
        line-height: 32px;
        padding: 0 5%;
      }

      a{
        display: none;
      }
    }

    &__slide > div{
      flex-direction: column;
      align-items: center;
      width: 70%;
      margin: auto;
    }

    &__link {
      width: 100%;
      text-align: center;
      font: 15px/21px sf_ui_displaymedium;
      color: #6e8494;
      background-color: #dfe5e9;
      padding: 12px 0;
      margin-bottom: 50px;
    }
  }

  .swiper-button-next {
    top: 35%;
  }

  .swiper-button-prev {
    top: 35%;
  }

}