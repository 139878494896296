.button,.submit,.button:hover,.submit:hover {
  display: block;
  width: 100%;
  height: 38px;
  border-radius: 3px;
  background-image: -moz-linear-gradient( 90deg, rgb(74,120,174) 0%, rgb(88,135,189) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgb(74,120,174) 0%, rgb(88,135,189) 100%);
  background-image: -ms-linear-gradient( 90deg, rgb(74,120,174) 0%, rgb(88,135,189) 100%);
  color: #ffffff;
  font: 16px/38px sf_ui_displayregular;
  text-align: center;
  cursor: pointer;
  text-decoration: none;

  &-w30{
    width: 30%;
  }

  &-lightBlue {
    background-color: #5CC3FF;
  }
}

.submit,.submit:hover {
  height: 50px !important;
  font-size: 13px !important;
}

@media screen and ( max-width: 900px) {

  .button {


    &-w30 {
      width: 50%;
    }
  }
}


@media screen and ( max-width: 767px) {

  .button {


    &-w30 {
      width: 100%;
    }
  }
}

