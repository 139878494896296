.top-block {
 padding: 10px 0;
 background-color: rgb(74, 120, 174);
 vertical-align: middle;


 &__phone,&__phone:hover,&__phone:active {
   font-family: sf_ui_displayregular;
   font-size: 16px;
   color: #ffffff;
   text-decoration: none;
  }

  &__socnet {
   margin-left: 40px;


   &:first-of-type {
    margin-left: 0;
   }
  }
}