.content {
  font: 16px/27px sf_ui_displaylight;
  padding-bottom: 158px;

  h1 {
    font: 40px/50px sf_ui_displayregular;
  }

  p {
    //white-space: pre-line;
  }

  &__imgText {
    width: 100%;
    background-color: #f0f5f8;
    margin: 50px 0;

    p {
      padding: 40px 50px;
      font: 21px/32px sf_ui_displaymedium;
    }

  }

  &__white {
    background: #ffffff;
    padding: 55px;
    margin-bottom: 77px;

    &-pl-negative {
      padding-left: 7.14%;
      margin-left: -7.14%;
    }
  }

  &__person {
    display: flex;
    width: 100%;
    background-color: #f0f5f8;
    flex-direction: column;
    width: 80%;
    margin: auto;
    text-align: center;

    img {
      width: 100%;
      height: auto;
    }

    a,a:hover {
      padding-top: 30px;
      color: #2b587a;
    }

    span {
      padding: 25px 0;
    }

    &-widthAuto {
      width: 260px;
      float: right;
      margin-left: 50px;
      margin-bottom: 50px;
    }
  }

  &__link {
    font-family: sf_ui_displaymedium;

    &-big {
      font-size: 21px;
      line-height: 32px;
    }
  }

  &__hidden {

    &.hidden {
      display: none;
    }
  }

  &__accentBlue {
    padding: 40px 50px;
    background-color: #f0f5f8;
    font: 21px/32px sf_ui_displaymedium;

    &-hidden {
      font: 12px/21px sf_ui_displaylight;
      padding: 20px;
      //display: flex;
      //align-items: flex-start;
      //align-content: flex-start;

      img {
        max-width: 100%;
        height: 100%;
        float: left;
        margin-right: 20px;
      }

      p {
        margin: 0;
        margin-left: 20px;
      }

      &.hidden {
        display: none;
      }
    }
  }

  &__flex-block {
    display: flex;

    img {
      max-width: 100%;
      height: 100%;
    }

    &-column {
      flex-direction: column;
    }

    &-center {
      align-content: center;
    }

    &-space-between {
      justify-content: space-between;
    }
  }

  &__dropdown {

    li span {
      cursor: pointer;
      color: #2b587a;
    }
  }
}

@media screen and (max-width: 1200px) {
  .content {
    &__white {
      &-pl-negative {

        margin: 0;
        padding-left: 55px;
      }
    }

    h1 {
      font-size: 35px;
    }
  }
}

@media screen and (max-width: 767px) {

  .content {

    h1,h2 {
      text-align: center;
    }

    padding-bottom: 30px;

    &__text-wrap {
      padding: 0 25px 0 25px;

      h1 {
        font-size: 24px;
        line-height: 32px;
      }

      p {
        font-size: 14px;
        line-height: 21px;
      }
    }

    &__white {
      padding: 25px;

      &-pl-negative {
        padding-left: 25px;
        margin-left: 0;
      }
    }
    &__person {

      &-widthAuto {
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__accentBlue {
      padding: 10px 20px;
    }

    &__flex-block {
      flex-direction: column;

      & > div {
        order: 2;
      }

      img {
        order : 1;
        width: 100%;
        margin-bottom: 10px;

      }

      }


    &__imgText {

      p {
        padding: 10px 20px;
      }

    }
    }
  }
