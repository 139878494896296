.contact-block {
  font-family: sf_ui_displaylight;
  font-size: 16px;
  line-height: 21px;
  color: #2b587a;
  background-color: #ffffff;

  &-flex-wrap {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &-mobile {
    background-color: #ffffff;
    //padding: 0 5%;
    display: none;
    img {
      padding: 14px;

    }

    img:first-child {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__email {

    a {
      color: #2b587a;
      text-decoration: underline;
    }

    a[href^="tel"]{
      color: #000;
      text-decoration: none;
    }
  }

  &__title {
    color: #000;
    img {
      padding: 24px 34px;
    }

    * {
      display: inline-block;
    }

    &-pl-zero{
      img {
        padding-left: 0;
      }
    }
  }

  &__contacts {
    text-align: right;
    color: #000;

    p {
      margin:0;
    }

    span {
      color : #9f9f9f;
    }
  }
}


@media screen and ( max-width: 767px) {
  .contact-block {

    &-mobile {
      display: flex;
    }
  }
}