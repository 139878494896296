.near-posts {
  background-color: white;
  padding: 30px 40px;
  display: flex;
  justify-content: space-between;

  &__prev, &__next{
    display: flex;
    align-items: center;
    align-content: flex-end;

    a.link{
      max-width: 145px;
    }
  }

  &__next {

    a.link {
      margin-right: -20px;
    }

  }

  &__thumb {
    max-width: 90px;
    max-height: 90px;
    margin: 0 35px;
  }

  &__arrow {

    max-width: 100%;

    &-next {

    }
  }
}


@media screen and (max-width: 767px) {
  .near-posts {
    margin-top: 80px;

    &__next {

      a.link {
        margin-right: 0;
        max-width: 100px;
      }

    }

    &__prev {

      a.link {
        margin-left: 20px;
        max-width: 100px;
      }

    }

    .near-posts__thumb {
      display: none;
    }
  }
}