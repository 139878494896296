.mobile-menu {
  display: none;
  position: absolute;
  width: 100%;
  min-height: 100%;
  background-color: #33435d;
  z-index: 15;
  overflow: scroll;

  &__header,&__footer {
    display: flex;
    height: 100px;
    justify-content: space-around;
    align-items: center;
    background-color: #263142;
  }

  &__title {
    color : #b5bcc7;
    font: 15px/21px sf_ui_displayregular;
    text-transform: uppercase;
  }

  &__content {
    position: relative;
    overflow: scroll;
    min-height: 320px !important;


    ul,ul.sub-menu {
      position: static;
      display: block;
      list-style: none;
      padding-left: 0;
      margin: 0 27px;


      li {


          a {
            display: block;
            font: 15px/21px sf_ui_displayregular;
            text-transform: uppercase;
            color: #ffffff;
            padding: 20px 0;
            border-bottom: 1px solid #5a6a83 ;
          }

        .sub-menu {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding-top: 0;
          background-color: #33435d;
          li {
            background-color: #33435d;
          }
        }
      }
    }
  }

  &__footer {
    position: static;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: -110px;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
  }

  &__socicons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 2;
  }




}