.icon-links {

  padding: 23px 0;

  &__links{
    list-style: none;
    padding: 0;
  }

  &__li {
    padding: 13px 0;
  }

  &__link {
    font: 15px/21px sf_ui_displaymedium;
    text-decoration: underline;
  }

  &__link,&__link:hover,&__icon {
    color: #6e8494;
  }

  &__icon {
    margin-right: 16px;
    font-size: 16px;
    min-width: 16px;
  }
}