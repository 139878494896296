.comment-list {
  list-style: none;
  padding: 0;
}

.comment {
  display: block;
  padding: 40px 60px;
  background-color: white;
  margin-bottom: 42px;

  .comment-author {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    p {
      width: 100%;
    }

    .dateAndAuthor {
      display: flex;
      justify-content: space-between;

      .fn {
        margin-right: 35px;
        font: 15px/21px sf_ui_displaymedium;

      }

      .commentmetadata {
        font: 13px/21px sf_ui_displaylight;
        color: #9f9f9f;
      }
    }
  }
  .attachmentFile {
    order: 99;
    font: 15px/21px sf_ui_displaymedium;
  }
}

/* Базовые стили слоя затемнения и модального окна  */
.overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none;
  /* фон затемнения */
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed; /* фиксированное поцизионирование */
  cursor: default; /* тип курсара */
}
/* активируем слой затемнения */
.overlay:target {
  display: block;
}
/* стили модального окна */
.popup {
  top: -100%;
  right: 0;
  left: 50%;
  font-size: 14px;
  z-index: 20;
  margin: 0;
  width: 85%;
  min-width: 320px;
  max-width: 600px;
  /* фиксированное позиционирование, окно стабильно при прокрутке */
  position: fixed;
  padding: 15px;
  border: 1px solid #383838;
  background: #fefefe;
  /* скругление углов */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  font: 14px/18px 'Tahoma', Arial, sans-serif;
  /* внешняя тень */
  -webkit-box-shadow: 0 15px 20px rgba(0,0,0,.22),0 19px 60px rgba(0,0,0,.3);
  -moz-box-shadow: 0 15px 20px rgba(0,0,0,.22),0 19px 60px rgba(0,0,0,.3);
  -ms-box-shadow: 0 15px 20px rgba(0,0,0,.22),0 19px 60px rgba(0,0,0,.3);
  box-shadow: 0 15px 20px rgba(0,0,0,.22),0 19px 60px rgba(0,0,0,.3);
  -webkit-transform: translate(-50%, -500%);
  -ms-transform: translate(-50%, -500%);
  -o-transform: translate(-50%, -500%);
  transform: translate(-50%, -500%);
  -webkit-transition: -webkit-transform 0.6s ease-out;
  -moz-transition: -moz-transform 0.6s ease-out;
  -o-transition: -o-transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
}
/* активируем модальный блок */
.overlay:target+.popup {
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  top: 20%;
}
/* формируем кнопку закрытия */
.close {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAKlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAKE86IAAAADXRSTlMAiTWvNK4zMrAxLi20i843cgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAABySURBVBjTYxBigANFBmUHGJvFiIElDMZJBQpXJEDYbO0g2XAIpxSsfkYBiGTvhGg0A5HJUIMyJjAwcLZBjWELYWBwTYAZ6jGBswVuNXvU0gKEQ/acZsDOQVGGbACK0ciWojgH2aEoXkD2HIq3UQIEOagAk9EbOxAmUvMAAAAASUVORK5CYII=');
  top: 40px;
  right: 40px;
  width: 24px;
  height: 24px;
  position: absolute;
  //padding: 0;
  //border: 2px solid #ccc;
  //-webkit-border-radius: 15px;
  //-moz-border-radius: 15px;
  //-ms-border-radius: 15px;
  //-o-border-radius: 15px;
  //border-radius: 15px;
  //background-color: rgba(61, 61, 61, 0.8);
  //-webkit-box-shadow: 0px 0px 10px #000;
  //-moz-box-shadow: 0px 0px 10px #000;
  //box-shadow: 0px 0px 10px #000;
  //text-align: center;
  //text-decoration: none;
  //font: 13px/20px 'Tahoma', Arial, sans-serif;
  //font-weight: bold;
  //-webkit-transition: all ease .8s;
  //-moz-transition: all ease .8s;
  //-ms-transition: all ease .8s;
  //-o-transition: all ease .8s;
  //transition: all ease .8s;
}
//.close:before {
//  color: rgba(255, 255, 255, 0.9);
//  content: "X";
//  text-shadow: 0 -1px rgba(0, 0, 0, 0.9);
//  font-size: 12px;
//}
//.close:hover {
//  background-color: rgba(252, 20, 0, 0.8);
//  -webkit-transform: rotate(360deg);
//  -moz-transform: rotate(360deg);
//  -ms-transform: rotate(360deg);
//  -o-transform: rotate(360deg);
//  transform: rotate(360deg);
//}
/* изображения внутри окна */

.form-title-wrap {
  display: flex;
  justify-content: space-between;

  .button {
    width: 240px;
  }
}

.success {

    background-color: #eaf1ff;
    width: 90%;
    max-width: 480px;
    height: 290px;
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

    p {
      color: #63768c;
      text-align: center;
      font: 36px/50px sf_ui_displaylight;
      margin: auto;
    }

  }


@media screen and (max-width: 767px){
  .form-title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .overlay:target+.popup {
    top: 5%;
  }


}